import CompeteService from 'compete-service'

const state = {
  scores: [],
  categories: [],
  challenges: [],
  contests: [],
  scoreboards: [],
}

const getters = {
  categories: (state) => {
    return state.categories
  },
  challenges: (state) => {
    return state.challenges
  },
  contests: (state) => {
    return state.contests
  },
  contestForId: (state) => (id) => {
    return state.contests.find(contest => {
      return contest._id === id
    })
  },
  scoreboards: (state) => {
    return state.scoreboards
  },
  allScores: (state) => {
    return state.scores
  },
  highScores: (state) => (count) => {
    return state.scores.slice(0, count)
  },
  hasCompleted: (state, getters, rootState, rootGetters) => (challengeId) => {
    // console.log(rootGetters['auth/user'])
    const challengeCompletions = rootGetters['auth/user']?.completions.challenges || []
    return challengeCompletions.find(completion => {
      return completion.challenge === challengeId
    })
  },
}

const mutations = {
  updateScores: (state, scores) => {
    state.scores = scores
  },
  updateCategories (state, categories) {
    state.categories = categories
  },
  updateChallenges (state, challenges) {
    state.challenges = challenges
  },
  updateContests (state, contests) {
    state.contests = contests
  },
  updateScoreboards (state, scoreboards) {
    state.scoreboards = scoreboards
  },
}

const actions = {
  async fetchScores ({ commit }) {
    const scores = await CompeteService.fetchScoreboard()
    commit('updateScores', scores)
  },
  async fetchScoreboards ({ commit }) {
    const scoreboards = await CompeteService.getAllScoreboards()
    commit('updateScoreboards', scoreboards)
  },
  async fetchUserScoreForContest ({ commit }, { contestId, userId }) {
    return await CompeteService.fetchUserScoreForContest(contestId, userId)
  },
  async generateNewGameBoard ({ commit }) {
    return await CompeteService.generateNewGameBoard()
  },
  async fetchCompeteData ({ dispatch }) {
    Promise.all([
      await dispatch('fetchContests'),
      await dispatch('fetchCategories'),
      await dispatch('fetchChallenges'),
    ])
  },
  async fetchCategories ({ commit }) {
    const categories = await CompeteService.getAllCategories()
    commit('updateCategories', categories)
  },
  async fetchChallenges ({ commit }) {
    const challenges = await CompeteService.getAllChallenges()
    commit('updateChallenges', challenges)
  },
  async fetchContests ({ commit }) {
    const contests = await CompeteService.getAllContests()
    commit('updateContests', contests)
  },
}

export default { namespaced: true, state, getters, mutations, actions }
