import Buffer from 'buffer'

import Axios from 'axios'

const fetchAndSetImageSource = (el, binding) => {
  console.log('fetching image...')
  if (binding.oldValue === undefined || binding.value !== binding.oldValue) {
    const url = `${binding.value}`

    Axios.get(url, { responseType: 'arraybuffer' })
      .then(response => {
        console.log(response.status)
        if (response.status === 204) {
          el.src = ''
        } else {
        // Do stuff here
          var mimeType = response.headers['content-type'].toLowerCase()
          var imgBase64 = Buffer.Buffer.from(response.data, 'binary').toString(
            'base64',
          )
          el.src = `data:${mimeType};base64,${imgBase64}`
        }
      })
      .catch((err) => {
        console.log('Error...')
        console.error(err)
        el.src = ''
      })
  }
}

export default {
  bind (el, binding) {
    fetchAndSetImageSource(el, binding)
  },

  update (el, binding) {
    fetchAndSetImageSource(el, binding)
  },
}
