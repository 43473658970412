import MetadataService from 'metadata-service'

const state = {
  siteMetadata: undefined,
  popupReminder: localStorage.getItem('dca-popupReminder') || false,
}

const getters = {
  siteMetadata: (state) => {
    return state.siteMetadata
  },
  approvedRegistrationDomains: (state) => {
    return state.siteMetadata.registrationDomains
  },
  showPopupReminder: (state) => {
    return !state.popupReminder
  },
}

const mutations = {
  loadData: (state, metadata) => {
    state.siteMetadata = metadata
  },
}

const actions = {
  async fetchSiteMetadata ({ commit }) {
    const metadata = await MetadataService.getSiteMetadata()
    commit('loadData', metadata)
  },
  async updatePopupReminder ({ state }, viewed) {
    localStorage.setItem('dca-popupReminder', !!viewed)
    state.popupReminder = !!viewed
  },
}

export default { namespaced: true, state, getters, mutations, actions }
