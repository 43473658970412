import Axios from 'axios'

export default {
  async getTracks () {
    try {
      const response = await Axios.get('/learn/tracks')
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async getCourses () {
    try {
      const response = await Axios.get('/learn/courses')
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  // newly added method
  async getLessons () {
    try {
      const response = await Axios.get('/learn/lessons')
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async getCourse (id) {
    try {
      const response = await Axios.get(`/learn/courses/${id}`)
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async getSSTJourneyMaps () {
    try {
      const response = await Axios.get('/learn/sst/course-maps')
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async upVoteLesson (lessonId) {
    try {
      const response = await Axios.put(`/learn/lessons/upVote/${lessonId}`)
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async downVoteLesson (lessonId) {
    try {
      const response = await Axios.put(`/learn/lessons/downVote/${lessonId}`)
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async addLessonComment (lessonId, payload) {
    try {
      const response = await Axios.put(`/learn/lessons/addComment/${lessonId}`, { payload: payload })
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async removeLessonComment (lessonId, payload) {
    try {
      const response = await Axios.patch(`/learn/lessons/removeComment/${lessonId}`, { payload: payload })
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async addLessonCompletion (lessonId) {
    try {
      const response = await Axios.put(`/learn/lessons/complete/${lessonId}`)
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
  async removeLessonCompletion (lessonId) {
    try {
      const response = await Axios.delete(`/learn/lessons/complete/${lessonId}`)
      return response.data.data
    } catch (err) {
      return undefined
    }
  },
}
