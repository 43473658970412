import Axios from 'axios'

export const addTeam = async (team) => {
  const result = await Axios.post('/teams', team).catch(err => {
    console.log('Error adding team')
    console.log(err)
    return { result: false, errors: err.data.errors }
  })

  if (result.data.errors.length > 0) {
    return { result: false, errors: result.data.errors }
  }

  return { result: true }
}

export const fetchTeams = async () => {
  const response = await Axios.get('/teams').catch(err => {
    console.log('Error fetching teams')
    console.log(err)
    return []
  })

  return response.data.data
}

export const fetchCollection = async (teamId, listId) => {
  const response = await Axios.get(`/teams/${teamId}/lists/${listId}`).catch(err => {
    console.log('Error fetching colection')
    console.log(err)
    return []
  })

  return response.data.data
}

export const deleteTeam = async (id) => {
  const result = await Axios.delete(`/teams/${id}`).catch(err => {
    console.log('Error deleting team')
    console.log(err)
    return { result: false, errors: err.data.errors }
  })

  if (result.data.errors.length > 0) {
    return { result: false, errors: result.data.errors }
  }

  return { result: true }
}

export const updateTeam = async ({ team, teamID }) => {
  const result = await Axios.put(`/teams/${teamID}`, team).catch(err => {
    console.log('Error updating team')
    console.log(err)
    return { result: false, errors: err.data.errors }
  })

  if (result.data.errors.length > 0) {
    return { result: false, errors: result.data.errors }
  }

  return { result: true }
}

export const createCollection = async (collection, id) => {
  const response = await Axios.post(`/teams/${id}/lists`, collection).catch(err => {
    console.log('Error adding Collection')
    console.log(err)
    return []
  })
  return response.data.data
}

export const deleteCollection = async (teamID, collectionID) => {
  const result = await Axios.delete(`/teams/${teamID}/lists/${collectionID}`).catch(err => {
    console.log('Error deleting collection')
    console.log(err)
    return { result: false, errors: err.data.errors }
  })

  if (result.data.errors.length > 0) {
    return { result: false, errors: result.data.errors }
  }

  return { result: true }
}

export const inviteUser = async (userEmail, teamID) => {
  const response = await Axios.post(`/teams/${teamID}/invites`, userEmail).catch(err => {
    console.log('Error adding user')
    console.log(err)
    return []
  })
  return response.data.data
}

export const acceptInvite = async (userID, teamID) => {
  console.log(userID, teamID)
  const result = await Axios.post(`/teams/${teamID}/invites/${userID}/accept`).catch(err => {
    console.log('Error accepting invite')
    console.log(err)
    return { result: false, errors: err.data.errors }
  })

  if (result.data.errors.length > 0) {
    return { result: false, errors: result.data.errors }
  }

  return { result: true }
}

export const declineInvite = async (userID, teamID) => {
  const result = await Axios.post(`/teams/${teamID}/invites/${userID}/decline`).catch(err => {
    console.log('Error declining invite')
    console.log(err)
    return { result: false, errors: err.data.errors }
  })

  if (result.data.errors.length > 0) {
    return { result: false, errors: result.data.errors }
  }

  return { result: true }
}

export const deleteInvite = async (userID, teamID) => {
  const result = await Axios.delete(`/teams/${teamID}/invites/${userID}`).catch(err => {
    console.log('Error deleting user invite')
    console.log(err)
    return { result: false, errors: err.data.errors }
  })

  if (result.data.errors.length > 0) {
    return { result: false, errors: result.data.errors }
  }

  return { result: true }
}

export const deleteUser = async (teamID, userID) => {
  const result = await Axios.delete(`/teams/${teamID}/users/${userID}`).catch(err => {
    console.log('Error deleting user')
    console.log(err)
    return { result: false, errors: err.data.errors }
  })

  if (result.data.errors.length > 0) {
    return { result: false, errors: result.data.errors }
  }

  return { result: true }
}

export const removeItemFromCollection = async (teamId, collectionId, itemId) => {
  const result = await Axios.delete(`/teams/${teamId}/lists/${collectionId}/items/${itemId}`).catch(err => {
    console.log('Error removing item')
    console.log(err)
    return { result: false, errors: err.data.errors }
  })

  if (result.data.errors.length > 0) {
    return { result: false, errors: result.data.errors }
  }

  return { result: true }
}

export const getTeamById = async (id) => {
  const response = await Axios.get(`/teams/${id}`).catch(err => {
    console.log('Error getting team')
    console.log(err)
    return []
  })
  return response.data.data
}

export const getCollectionById = async (teamId, collectionId) => {
  const response = await Axios.get(`/teams/${teamId}/lists/${collectionId}`).catch(err => {
    console.log('Error getting collection')
    console.log(err)
    return []
  })
  return response.data.data
}

export const addItemToCollection = async (teamId, collectionId, itemToAdd) => {
  const response = await Axios.put(`/teams/${teamId}/lists/${collectionId}/items`, itemToAdd).catch(err => {
    console.log('Error adding item to collection')
    console.log(err)
    return []
  })
  return response.data.data
}
