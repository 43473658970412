import PracticeService from 'practice-service'

export default {
  namespaced: true,
  state: {
    categories: [],
    modules: [],
  },
  getters: {
    categories: (state) => {
      return state.categories
    },
    modules: (state) => {
      return state.modules
    },
    categoryWithId: (state) => (id) => {
      return state.categories.find(category => {
        return category._id === id
      })
    },
    moduleWithId: (state) => (id) => {
      return state.modules.find(module => {
        return module._id === id
      })
    },
    hasCompleted: (state, getters, rootState, rootGetters) => (id) => {
      const completions = rootGetters['auth/user']?.completions.practiceModules || []
      return completions.find(completion => {
        return completion.practiceModule._id === id
      }) !== undefined
    },
  },
  mutations: {
    updateCategories (state, categories) {
      state.categories = categories
    },
    updateModules (state, modules) {
      state.modules = modules
    },
  },
  actions: {
    async fetchCategories ({ commit }) {
      const categories = await PracticeService.getCategories()
      const modules = [...new Set(categories.map(c => c.modules).flat())]
      commit('updateCategories', categories)
      commit('updateModules', modules)
    },
    async fetchModules ({ commit }) {
      const modules = await PracticeService.getModules()
      commit('updateModules', modules)
    },
    async fetchPracticeData ({ dispatch }) {
      dispatch('fetchCategories')
    },
    async upVoteModule ({ commit }, { moduleId }) {
      return await PracticeService.upVoteModule(moduleId)
    },
    async downVoteModule ({ commit }, { moduleId }) {
      return await PracticeService.downVoteModule(moduleId)
    },
    async addModuleComment ({ commit }, { moduleId, payload }) {
      return await PracticeService.addModuleComment(moduleId, payload)
    },
    async removeModuleComment ({ commit }, { moduleId, payload }) {
      return await PracticeService.removeModuleComment(moduleId, payload)
    },
    async addModuleCompletion ({ commit }, { moduleId }) {
      return await PracticeService.addModuleCompletion(moduleId)
    },
    async removeModuleCompletion ({ commit }, { moduleId }) {
      return await PracticeService.removeModuleCompletion(moduleId)
    },
  },
}
