<template>
  <div class="flex flex-col items-center bg-gray-100">

    <!-- black background for flyout menu -->
    <div :class="`${ !isContactUsPanelOpen ? 'bg-opacity-0 pointer-events-none' : 'bg-opacity-30'} fixed top-0 left-0 min-h-screen w-full z-40 bg-black`" @click="closeAllPanels"/>

    <!-- contactUsPanel -->
    <div v-if="isContactUsPanelOpen"
        class="fixed z-40 right-0 top-0 md:top-48 bg-white w-full lg:w-1/2 xl:w-1/3 shadow-lg rounded overflow-scroll"
        :class="{'translate-x-full': !isContactUsPanelOpen, 'translate-x-0': isContactUsPanelOpen}"
      >
      <div class="flex flex-col space-y-6 p-6 flex-0">

        <div class="flex w-full justify-between items-center">
          <div class="text-mitre-blue text-2xl font-semibold">Get in touch with us</div>
          <button @click="isContactUsPanelOpen = !isContactUsPanelOpen">
            <CloseIcon class="h-8 text-black fill-current cursor-pointer p-1" />
          </button>
        </div>

        <div>
          <div class="text-slate-500 text-sm uppercase pb-1">Name</div>
          <input
            :disabled="isContactUsFormSubmitted"
            type="text"
            v-model="senderName"
            placeholder="John Doe"
            class="w-full px-2 py-2 rounded bg-white focus:outline-none placeholder-slate-600 border-2 rounder border-slate-500 shadow-inner placeholder-opacity-75"
          />
        </div>

        <div>
          <div class="text-slate-500 text-sm uppercase pb-1">Email Address</div>
          <input
            :disabled="isContactUsFormSubmitted"
            type="text"
            v-model="senderEmail"
            placeholder="john.doe@mitre.org"
            class="w-full px-2 py-2 rounded bg-white focus:outline-none placeholder-slate-600 border-2 rounder border-slate-500 shadow-inner placeholder-opacity-75"
          />
        </div>

        <div>
          <div class="text-slate-500 text-sm uppercase pb-1">Message</div>
          <textarea
            :disable="isContactUsFormSubmitted"
            placeholder="Message"
            class="w-full h-28 px-2 py-2 rounded bg-white focus:outline-none placeholder-slate-600 border-2 rounder border-slate-500 shadow-inner placeholder-opacity-75"
            v-model="message"
          />
        </div>
        <div>
          <div :class="`text-sm font-bold text-red-600 uppercase text-center ${!isNameError && !isEmailError && !isMessageError && 'invisible'}`">Please complete all fields</div>
          <div @click="!isContactUsFormSubmitted ? onSubmitContactUsForm() : {}" class="w-full py-3 font-semibold text-white bg-mitre-blue rounded text-center text-lg cursor-pointer hover:bg-opacity-90 transition-all duration-300 ease-in-out">{{ !isContactUsFormSubmitted ? 'Send Request' : 'Request Submitted!'}}</div>
        </div>
      </div>
    </div>

    <div ref="header" :class="{'border-none bg-gray-100 py-4': !scrolled, 'bg-white shadow-lg py-3': scrolled || isRequestQuotePanelOpen , 'sticky top-0': !isRequestQuotePanelOpen}" class="flex justify-center z-30 w-full transition-all duration-300">

      <div class="flex flex-col space-y-3 lg:flex-row lg:space-y-0 items-center justify-between w-10/12">
        <div class="flex space-x-4 text-mitre-navy items-center">
            <MitreLogo class='h-10 cursor-pointer' @click="isRequestQuotePanelOpen = false" />
        </div>

        <div class="hidden sm:flex space-x-4">
          <a href="https://xacademy.mitre.org/sandbox/" target="_blank">
            <button class="text-center w-32 py-1 text-white text-lg font-semibold bg-mitre-blue rounded cursor-pointer hover:bg-opacity-90 transition-all duration-300 ease-in-out flex items-center justify-center">
              <div>Demo</div>
              <ForumArrow class="text-white pl-2 w-5 h-5 fill-current"/>
            </button>
          </a>
          <button class="text-center w-32 py-1 text-white text-lg font-semibold bg-mitre-blue rounded cursor-pointer hover:bg-opacity-90 transition-all duration-300 ease-in-out" @click="openRequestQuotePanel">Your Project</button>
          <button class="text-center w-32 py-1 text-white text-lg font-semibold bg-mitre-blue rounded cursor-pointer hover:bg-opacity-90 transition-all duration-300 ease-in-out" @click="openContactUsPanel">Contact</button>
        </div>

        <div class="sm:hidden flex text-center text-lg font-semibold text-black justify-evenly w-screen">
          <a href="https://xacademy.mitre.org/sandbox/" target="_blank">
            <div class="flex w-24 items-center justify-center">
              <div class="text-gray-800 uppercase">Demo</div>
              <ForumArrow class="text-gray-800 pl-2 w-5 h-5 fill-current"/>
            </div>
          </a>
          <div class="h-6 border border-gray-600" />
          <div class="flex w-24 justify-center uppercase cursor-pointer" @click="openRequestQuotePanel">
            <div class="text-gray-800">Design</div>
          </div>
          <div class="h-6 border border-gray-600" />
            <div class="flex w-24 justify-center uppercase cursor-pointer" @click="openContactUsPanel">
              <div class="text-gray-800">Contact</div>
            </div>
        </div>
      </div>

    </div>

    <div v-show="isRequestQuotePanelOpen" class="min-h-screen">
      <div class="z-10 bg-white my-4 w-full transition transform ease-in-out duration-500 shadow-lg rounded">

        <div v-if="isSuccess" class="flex flex-col space-y-6 p-6 flex-0 overflow-scroll text-center">
          <div class="text-mitre-blue text-2xl font-semibold">Thanks for your submission!</div>

          <div class="flex flex-col items-center space-y-5 my-12">
            <md-icon class="md-size-4x">thumb_up</md-icon>
            <div class="font-semibold tracking-wide">A member of the xAcademy team will contact you soon.</div>
            <div class="w-48">
              <div @click="isRequestQuotePanelOpen = !isRequestQuotePanelOpen" class="w-full py-3 font-semibold text-white bg-mitre-blue rounded text-center text-lg cursor-pointer hover:bg-opacity-90 transition-all duration-300 ease-in-out">Return to Home</div>
            </div>
          </div>
        </div>

        <div v-else class="flex flex-col space-y-6 p-6 flex-0 overflow-scroll">
          <div class="flex w-full justify-between items-center">
            <div class="text-mitre-blue text-2xl font-semibold">Tell us about your project</div>
            <button @click="isRequestQuotePanelOpen = !isRequestQuotePanelOpen">
              <CloseIcon class="h-8 text-black fill-current cursor-pointer p-1" />
            </button>
          </div>

          <md-field :class="`${isNameError && 'md-invalid'}`">
            <label>Name</label>
            <md-input v-model="senderName" placeholder="Name"></md-input>
            <span class="md-error">A name is required</span>
          </md-field>

          <md-field :class="`${isEmailError && 'md-invalid'}`">
            <label>Email (Separate by commas if multiple)</label>
            <md-input v-model="senderEmail" placeholder="Email" required></md-input>
            <span class="md-error">Valid email is required</span>
          </md-field>

          <md-field :class="`${isMessageError && 'md-invalid'}`">
            <label>Tell us about your project</label>
            <md-textarea v-model="message" md-autogrow></md-textarea>
            <span class="md-error">A brief message is required</span>
          </md-field>

          <md-switch :disabled="isExistingSponsor" v-model="isMitreInternal">
            Is this for an internal MITRE project?
          </md-switch>

          <md-switch :disabled="isMitreInternal" v-model="isExistingSponsor">
            Is your project for an existing MITRE FFRDC sponsor?
          </md-switch>

          <md-switch v-if="isExistingSponsor" v-model="isContractorShare">
            Will you be sharing xAcademy with private contractors/partners?
          </md-switch>

          <div class="mb-6">
            <div class="flex space-x-2 mt-3">
              <div class="font-medium text-lg">Base Platform</div>
            </div>
            <div class="text-gray-600 pb-1 font-medium border-gray-700 border-b mb-1">xAcademy's base install includes a user portal and admin portal. Select from additional configurations and features below to customize your instance.</div>
            <div class="flex flex-col space-y-1 font-medium">
              <div class="flex items-start">
                <md-checkbox v-model="isCheckboxDisabled" disabled />
                <div class="flex flex-col my-2">
                  <div class="flex items-center space-x-4">
                    <div class="text-gray-800 font-bold">xAcademy User Portal</div>
                    <div class=" cursor-pointer">
                      <md-icon>info_outlined</md-icon>
                      <md-tooltip md-direction="right">
                        <div class="-ml-2 -mr-2 w-64 sm:w-96 h-44 sm:h-64 border-2 border-gray-500 rounded bg-white shadow-lg overflow-hidden p-2">
                          <div class="w-full h-full overflow-hidden">
                            <div class="text-xs font-medium text-black z-50 mb-1">xAcademy User Portal</div>
                            <img src="../assets/user-portal-tooltip.png" class="object-fit w-full h-full" />
                          </div>
                        </div>
                      </md-tooltip>
                    </div>
                  </div>
                  <div class="font-normal">The user portal is where learners can interact with the material and each other.</div>
                </div>
              </div>
              <div class="flex items-start">
                <md-checkbox v-model="isCheckboxDisabled" disabled />
                <div class="flex flex-col my-2">
                  <div class="flex items-center space-x-4">
                    <div class="text-gray-800 font-bold">xAcademy Admin Portal</div>
                    <div class=" cursor-pointer">
                      <md-icon>info_outlined</md-icon>
                      <md-tooltip md-direction="right">
                        <div class="-ml-2 -mr-2 w-64 sm:w-96 h-44 sm:h-64 border-2 border-gray-500 rounded bg-white shadow-lg overflow-hidden p-2">
                          <div class="w-full h-full overflow-hidden">
                            <div class="text-xs font-medium text-black z-50 mb-1">xAcademy Admin Portal</div>
                            <img src="../assets/admin-portal-tooltip.png" class="object-fit w-full h-full" />
                          </div>
                        </div>
                      </md-tooltip>
                    </div>
                  </div>
                  <div class="font-normal">The admin portal is where content curators can customize the content and gain insights into learning progress.</div>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-6">
            <div class="flex space-x-2">
              <div class="font-medium text-lg">Hosting</div>
            </div>
            <div class="text-gray-600 pb-1 font-medium border-gray-700 border-b mb-1">You can let MITRE host your instance of xAcademy, or deploy it on-prem for complete control.</div>
            <div class="flex flex-col space-y-1 font-medium">
              <div class="flex items-start">
                <md-radio v-model="selectedHost" value="mitre" class="md-primary" />
                <div class="flex flex-col my-2">
                  <div class="text-gray-800 font-bold">MITRE</div>
                  <div class="font-normal">MITRE can host your instance of xAcademy. Storage is limited and sensitive content is not approved.</div>
                </div>
              </div>
              <div class="flex items-start">
                <md-radio v-model="selectedHost" :disabled="isMitreInternal" value="onPrem" class="md-primary" />
                <div class="flex flex-col my-2">
                  <div class="text-gray-800 font-bold">On-Prem</div>
                  <div class="font-normal">Easily deploy xAcademy on your infrastructure for complete control of content and unlimited storage.</div>
                </div>
              </div>
              <span v-if="isHostingError" :style="{ 'color': 'var(--md-theme-default-fieldvariant, #ff1744)', 'font-size': '12px' }">Hosting selection is required</span>
            </div>
          </div>

          <div class="mb-6">
            <div class="flex space-x-2">
              <div class="font-medium text-lg">Content Packages</div>
            </div>
            <div class="text-gray-600 pb-1 font-medium border-gray-700 border-b mb-1">You can populate xAcademy with the content from any of these domains. You can even request content curated by MITRE experts from any other domain. If you prefer a blank instance, select none.</div>
            <div class="flex flex-col font-medium">
              <div class="flex items-start mb-1">
                <md-checkbox v-model="isAgileAndDevOps" class="md-primary" />
                <div class="flex flex-col my-2">
                  <div class="text-gray-800 font-bold">Agile and DevOps</div>
                  <div class="font-normal">Optimize software development with content focused on Agile and DevOps best practices.</div>
                </div>
              </div>
              <div class="flex items-start mb-1">
                <md-checkbox v-model="isCyberSecurity" class="md-primary" />
                <div class="flex flex-col my-2">
                  <div class="text-gray-800 font-bold">Cyber Security</div>
                  <div class="font-normal">Learn security from the perspective of an attacker, featuring many interactive lessons that challenge users to hack systems.</div>
                </div>
              </div>
              <div class="flex items-start">
                <md-checkbox v-model="isWeb3" class="md-primary" />
                <div class="flex flex-col my-2">
                  <div class="text-gray-800 font-bold">Web3</div>
                  <div class="font-normal">Understand the basics of blockchain technologies and explore the current innovations happening in Web3.</div>
                </div>
              </div>
              <div class="flex items-start">
                <md-checkbox v-model="isDataManagement" class="md-primary" />
                <div class="flex flex-col mt-2">
                  <div class="text-gray-800 font-bold">Data Management</div>
                  <div class="font-normal">With content generated from our AI Assistant engine, equip your enterprise with timely information from Data Stewardship to Analysis to Management.</div>
                </div>
              </div>
              <div class="flex items-center">
                <md-checkbox v-model="isOtherContent" class="md-primary" />
                <md-field md-inline>
                  <label>Other: What do you want to learn?</label>
                  <md-input v-model="otherContentText"></md-input>
                </md-field>
              </div>
            </div>
          </div>

          <div class="mb-6">
            <div class="flex space-x-2">
              <div class="font-medium text-lg">Optional Features</div>
            </div>
            <div class="text-gray-600 pb-1 font-medium border-gray-700 border-b mb-1">Select to enable these capabilities for your instance of xAcademy.</div>
            <div class="flex flex-col space-y-1 font-medium">
              <div class="flex items-start">
                <md-checkbox v-model="isCustomURL" class="md-primary" :disabled="selectedHost === 'onPrem'" />
                <div :class="`flex flex-col my-2 transition-all duration-200 ${selectedHost === 'onPrem' && 'opacity-70'}`">
                  <div class="text-gray-800 font-bold">Custom URL</div>
                  <div class="font-normal">Only pertains to MITRE-hosted instances. Support for custom URL rather than default xAcademy url: https://xacademy.mitre.org/yourInstance</div>
                </div>
              </div>
              <div class="flex items-start">
                <md-checkbox v-model="isAiAssistant" class="md-primary" />
                <div class="flex flex-col my-2">
                  <div class="text-gray-800 font-bold">AI Assistant</div>
                  <div class="font-normal">Effortlessly generate comprehensive Tracks, Courses, Lessons, and Assessments (if enabled) on any topic of your choice</div>
                </div>
              </div>
              <div class="flex items-start">
                <md-checkbox v-model="isBadgeEngine" class="md-primary" />
                <div class="flex flex-col my-2">
                  <div class="text-gray-800 font-bold">Badge Engine</div>
                  <div class="font-normal">Design badge programs to highlight specific learning paths and showcase completion</div>
                </div>
              </div>
              <div class="flex items-start">
                <md-checkbox v-model="isAssessmentEngine" class="md-primary" />
                <div class="flex flex-col my-2">
                  <div class="text-gray-800 font-bold">Assessment Engine</div>
                  <div class="font-normal">Support for assessments to track user completions and training requirements</div>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-6">
            <div class="flex space-x-2">
              <div class="font-medium text-lg">Recommended Services</div>
            </div>
            <div class="text-gray-600 pb-1 font-medium border-gray-700 border-b mb-1">MITRE offers the following services to support xAcademy.</div>
            <div class="flex flex-col space-y-1 font-medium">
              <div v-if="selectedHost === 'onPrem'" class="flex items-start">
                <md-checkbox v-model="isInstallation" class="md-primary" />
                <div class="flex flex-col my-2">
                  <div class="text-gray-800 font-bold">Installation</div>
                  <div class="font-normal">Need help installing xAcademy? Let a MITRE expert install xAcademy on your infrastructure of choice</div>
                </div>
              </div>
              <div v-if="selectedHost === 'onPrem'" class="flex items-start">
                <md-checkbox v-model="isTraining" class="md-primary" />
                <div class="flex flex-col my-2">
                  <div class="text-gray-800 font-bold">Training</div>
                  <div class="font-normal">MITRE representatives will provide comprehensive training to get the most out of your xAcademy instance</div>
                </div>
              </div>
              <div class="flex items-start">
                <md-checkbox v-model="isCurriculumDesign" class="md-primary" />
                <div class="flex flex-col my-2">
                  <div class="text-gray-800 font-bold">Curriculum Design</div>
                  <div class="font-normal">MITRE subject matter experts can curate the content for your community of learners</div>
                </div>
              </div>
              <div class="flex items-start">
                <md-checkbox v-model="isGameDesign" class="md-primary" />
                <div class="flex flex-col my-2">
                  <div class="text-gray-800 font-bold">Game Design</div>
                  <div class="font-normal">MITRE's gaming team can create interactive experiences to help facilitate learning and competition among users</div>
                </div>
              </div>
              <div class="flex items-start">
                <md-checkbox v-model="isCustomDevelopment" class="md-primary" />
                <div class="flex flex-col my-2">
                  <div class="text-gray-800 font-bold">Custom Development</div>
                  <div class="font-normal">Need additional functionality for your learning portal? We can build it for you.</div>
                </div>
              </div>
              <div class="flex items-start">
                <md-checkbox v-model="isEventFacilitation" class="md-primary" />
                <div class="flex flex-col my-2">
                  <div class="text-gray-800 font-bold">Event Facilitation</div>
                  <div class="font-normal">Let MITRE help you create a memorable event/competition with worldwide participation.</div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div @click="!isRequestProposalFormSubmitted ? onSubmitRequestProposal() : {}" class="w-full py-3 font-semibold text-white bg-mitre-blue rounded text-center text-lg cursor-pointer hover:bg-opacity-90 transition-all duration-300 ease-in-out">{{ !isRequestProposalFormSubmitted ? 'Send Request' : 'Request Submitted!'}}</div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!isRequestQuotePanelOpen" class="w-full flex justify-center overflow-x-hidden pb-24">
      <div class="w-10/12">

      <div class="flex justify-between items-center space-x-4 pt-16">
        <div class="flex-1 space-y-10 text-mitre-blue text-5xl xl:text-7xl font-semibold leading-tight md:whitespace-nowrap">
          <div>One learning platform.</div>
          <div>Any community.</div>
          <div>Any domain.</div>
        </div>
        <img src="./hero_banner.png" class="h-72 hidden md:inline rounded pl-8 fade-to-nothing" />
      </div>

      <div class="pt-24 text-xl tracking-wide text-gray-600 leading-loose">
        Developed to meet the rigorous training requirements and varied use cases in industry, government, and academia, the xAcademy is an e'X'tensible learning platform and powerful solution for training across knowledge domains. With a user-friendly, no-code administration portal, the platform allows for effortless customization to rapidly onboard and up-skill student users. Benefitting from MITRE's expertise in gaming, UX design, and agile software development, the xAcademy undergoes continuous evolution of its rich functions and features. <button class="font-semi-bold text-mitre-blue underline cursor-pointer" @click="isContactUsPanelOpen = !isContactUsPanelOpen">Reach out</button> to the xAcademy team to explore how it can cater to your specific needs.
      </div>

      <div class="text-5xl lg:text-6xl text-mitre-blue font-semibold pt-24 pb-10">Background</div>

      <div class="text-xl tracking-wide text-gray-600 leading-loose">
        The xAcademy's origin story dates back to 2019, the result of an Air Force sponsored software development assessment. That evaluation identified the need for self-paced training solutions to advance service members, specifically in the domain of DevOps. After releasing the DevOps Coder Academy, a learning platform for AFCENT featuring DevOps and Agile resources curated by MITRE experts, our team quickly realized the applicability of this solution for other organizations. Since then, xAcademy instances have been stood up for partners at SOCOM, US Air Force, and AFRICOM, as well as leveraged by MITRE for both internal training and STEM outreach. We're continuously improving the platform with the goal of advancing educational tools for all communities of learners.
      </div>

      <div class="text-5xl lg:text-6xl text-mitre-blue font-semibold pt-24 pb-10">Features</div>

      <div class="flex flex-col items-center">
        <div class="flex flex-col space-y-2 md:flex-row items-center justify-between md:space-x-4 md:space-y-0">
          <CarouselWrapper class="flex-1 shadow" :currentPage="selectedFeatureCard">
            <slide v-for="card of featureCards" :key="card.id">
              <img :src="card.pic" class="rounded w-full h-full object-fit" />
            </slide>
          </CarouselWrapper>
          <div class="flex flex-col space-y-4 w-full md:w-4/12">
            <div v-for="card of featureCards" :key="card.id">
              <button class="flex focus:outline-none" @click=" selectedFeatureCard = card.id - 1">
                <div :class="`bg-white flex md:h-48 rounded shadow cursor-pointer hover:bg-gray-200 transition-all duration-300 ease-in-out`">
                  <div :class="`${card.id - 1 === selectedFeatureCard ? 'w-2' : 'w-0'} rounded-l transition-all duration-300 h-full bg-mitre-blue flex-none`" />
                  <div :class="`${card.id - 1 === selectedFeatureCard ? 'w-0' : 'w-2'} rounded-l transition-all duration-300 h-full flex-none`" />
                  <div class="flex flex-col p-5 text-left">
                    <div class="font-semibold text-mitre-blue pb-4">{{ card.title }}</div>
                    <div class="text-gray-600 overflow-auto leading-relaxed">{{ card.description }}</div>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="text-5xl lg:text-6xl text-mitre-blue font-semibold pt-24 pb-10">In Production</div>

      <div class="grid xl:grid-cols-5 gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        <div v-for="instance of productionInstances" :key="instance.link">
          <a class="flex justify-center px-2 rounded bg-white py-5 shadow cursor-pointer hover:bg-gray-200 transition-all duration-300 ease-in-out" :href="instance.link" target="_blank">
            <div class="font-semibold text-mitre-blue text-center">{{ instance.title }}</div>
            <ForumArrow class="pl-2 w-6 h-6 text-mitre-blue fill-current"/>
          </a>
        </div>
      </div>

      <div class="text-5xl lg:text-6xl text-mitre-blue font-semibold pt-24 pb-10">Deployment Options</div>

      <div class="flex flex-col space-x-0 space-y-4 md:flex-row md:space-x-8 justify-center md:space-y-0 xl:mx-24">
        <div class="flex-1 rounder bg-white rounded-lg shadow flex flex-col p-6">
          <div class="text-center text-xl text-mitre-blue font-semibold">MITRE Hosted</div>

          <div class="text-center pt-3 text-gray-600">MITRE can host a publicly-accessible or limited-access instance of xAcademy</div>

          <div class="flex flex-col space-y-3 mt-4">
            <div v-for="bullet of mitreHostingBullets" :key="bullet.text">
              <div class="flex items-center space-x-1">
                <CheckMark v-if="bullet.icon === 'check'" class="flex-none h-8 text-green-600 stroke-current" />
                <CloseIcon v-else class="flex-none h-8 text-red-500 fill-current" />
                <div class="text-gray-600">{{ bullet.text }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-1 rounder bg-white rounded-lg shadow flex flex-col p-6">
          <div class="text-center text-xl text-mitre-blue font-semibold">On-Prem Installation</div>

          <div class="text-center pt-3 text-gray-600">Deploy the containerized application on your hardware and network</div>

          <div class="flex flex-col space-y-3 mt-4">
            <div v-for="bullet of onPremBullets" :key="bullet">
              <div class="flex items-center space-x-1">
                <CheckMark class="flex-none h-8 text-green-600 stroke-current" />
                <div class="text-gray-600">{{ bullet }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-5xl lg:text-6xl text-mitre-blue font-semibold pt-24 pb-10">Additional Services</div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div v-for="service of additionalServices" :key="service.title" class="bg-white rounded-lg shadow flex flex-col p-6">
          <div class="text-center text-xl text-mitre-blue font-semibold">{{ service.title }}</div>

          <div class="flex flex-col space-y-3 mt-4">
            <div v-for="bullet of service.tags" :key="bullet">
              <div class="flex items-center space-x-1">
                <!-- <CheckMark class="flex-none h-8 text-mitre-blue stroke-current" /> -->
                <div class="h-2 w-2 rounded-full bg-mitre-blue p-1 mr-3" />
                <div class="text-gray-600">{{ bullet }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-5xl lg:text-6xl text-mitre-blue font-semibold pt-24 pb-10">Team</div>

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-8 text-xl tracking-wide text-gray-600 leading-loose">
        <div v-for="member of team" :key="member.name" class="flex space-x-2">
          <img class="h-12 w-12 rounded-full object-fit" :src="member.pic" />
          <div>{{ member.name }}</div>
        </div>
      </div>
      </div>
    </div>

    <div class='flex w-full shadow-lg font-medium justify-center text-center pb-4 text-gray-700'>
      <div>Approved for Public Release; Distribution Unlimited. Public Release Case Number 23-2209 ©2023 The MITRE Corporation. ALL RIGHTS RESERVED.</div>
    </div>
  </div>
</template>

<script>
import MitreLogo from '@/assets/mitre-logo.svg'
import CloseIcon from '@/assets/close-x.svg'
import CarouselWrapper from '@/components/utilities/CarouselWrapper.vue'
import userPortalImage from './userPortal.png'
import adminPortalImage from './adminPortal.png'
import instancesImage from './instances.png'
import { Slide } from 'vue-carousel'
import CheckMark from '@/assets/check-mark.svg'
import ForumArrow from '@/assets/forum-arrow.svg'
import Axios from 'axios'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'

import bobPic from './bob.png'
import julesPic from './jules.png'
import kevPic from './kev.png'
import patPic from './pat.png'
import maxPic from './max.png'
import drewPic from './drew.png'

export default {
  components: {
    MitreLogo,
    CloseIcon,
    CarouselWrapper,
    Slide,
    CheckMark,
    ForumArrow,
  },
  computed: {
    contactUsFormData () {
      return (
        {
          message: this.message,
          messageSubject: this.messageSubject,
          senderEmail: this.senderEmail,
          senderName: this.senderName,
        }
      )
    },
    intakeFormData () {
      return (
        {
          senderName: this.senderName,
          senderEmail: this.senderEmail,
          message: this.message,
          isMitreInternal: this.isMitreInternal,
          isExistingSponsor: this.isExistingSponsor,
          isContractorShare: this.isExistingSponsor && this.isContractorShare,
          contentPackages: {
            isAgileAndDevOps: this.isAgileAndDevOps,
            isCyberSecurity: this.isCyberSecurity,
            isWeb3: this.isWeb3,
            isDataManagement: this.isDataManagement,
            isOtherContent: this.isOtherContent,
            otherContentText: this.otherContentText,
          },
          selectedHost: this.selectedHost,
          optionalFeatures: {
            isCustomURL: this.isCustomURL,
            isAiAssistant: this.isAiAssistant,
            isBadgeEngine: this.isBadgeEngine,
            isAssessmentEngine: this.isAssessmentEngine,
          },
          additionalServices: {
            isInstallation: this.selectedHost === 'onPrem' && this.isInstallation,
            isTraining: this.selectedHost === 'onPrem' && this.isTraining,
            isCurriculumDesign: this.isCurriculumDesign,
            isGameDesign: this.isGameDesign,
            isCustomDevelopment: this.isCustomDevelopment,
            isEventFacilitation: this.isEventFacilitation,
          },
        }
      )
    },
  },
  watch: {
    isSuccess () {
      if (this.isSuccess) {
        window.scrollTo({
          top: 0,
        })
      }
    },
    isRequestQuotePanelOpen () {
      this.isSuccess = false
      this.isRequestProposalFormSubmitted = false
    },
    isOtherContent () {
      if (!this.isOtherContent) this.otherContentText = ''
    },
    otherContentText () {
      if (this.otherContentText.length > 0) this.isOtherContent = true
    },
    selectedHost () {
      if (this.selectedHost === 'onPrem') this.isCustomURL = false
    },
    isMitreInternal () {
      if (this.isMitreInternal) this.selectedHost = 'mitre'
    },
  },
  data () {
    return {
      // intake form options
      senderEmail: '',
      senderName: '',
      message: '',
      isMitreInternal: false,
      isExistingSponsor: false,
      isContractorShare: false,
      isAgileAndDevOps: false,
      isCyberSecurity: false,
      isWeb3: false,
      isDataManagement: false,
      isOtherContent: false,
      otherContentText: '',
      selectedHost: '',
      isCustomURL: false,
      isAiAssistant: false,
      isBadgeEngine: false,
      isAssessmentEngine: false,
      isInstallation: false,
      isTraining: false,
      isCurriculumDesign: false,
      isGameDesign: false,
      isCustomDevelopment: false,
      isEventFacilitation: false,
      // other vars
      isSuccess: false,
      isCheckboxDisabled: true,
      scrolled: false,
      isContactUsPanelOpen: false,
      isRequestQuotePanelOpen: false,
      isNameError: false,
      isEmailError: false,
      isMessageError: false,
      isHostingError: false,
      // senderEmail: '',
      // senderName: '',
      messageSubject: '',
      // message: '',
      isContactUsFormSubmitted: false,
      isRequestProposalFormSubmitted: false,
      additionalServices: [
        {
          title: 'Installation (On-Prem)',
          tags: ['Expert installation on your hardware and network of choosing', 'Let MITRE do the heavy lifting for you'],
        },
        {
          title: 'Training',
          tags: ['Tailored training that caters to the specific needs and goals of your organization', 'Learn the ins and outs of your xAcademy instance', 'Ensure your team is fully equipped to navigate and utilize all features of the platform'],
        },
        {
          title: 'Curriculum Design',
          tags: ['Create user personas for your various users', 'Craft journey maps, learning progressions and course lists', 'Identify and curate relevant content, endorsed by MITRE experts'],
        },
        {
          title: 'Custom Development',
          tags: ['Extend the functionality of the platform with unique features or capabilities', 'Dedicated and responsive development team', 'Create both admin and user capabilities'],
        },
        {
          title: 'Game Design',
          tags: ['Create memorable competitions for your users', 'Dedicated gaming team with focus on meaningful game design', 'Use interactive challenges to re-enforce learning'],
        },
        {
          title: 'Event Facilitation',
          tags: ['Conduct entire events, from registration and user onboarding through competition', 'MITRE has a history of facilitating competitions across STEM domains with worldwide participation'],
        },
      ],
      team: [
        {
          name: 'Dr. Robert Cherinka',
          pic: bobPic,
        },
        {
          name: 'Jules Burgo',
          pic: julesPic,
        },
        {
          name: 'Kevin Long',
          pic: kevPic,
        },
        {
          name: 'Patrick O\'Leary',
          pic: patPic,
        },
        {
          name: 'Drew Blumenthal',
          pic: drewPic,
        },
        {
          name: 'Max Cardillo',
          pic: maxPic,
        },
      ],
      mitreHostingBullets: [
        {
          text: 'Updated with latest development and features',
          icon: 'check',
        },
        {
          text: 'Fastest turnaround',
          icon: 'check',
        },
        {
          text: 'Not approved for controlled unclassified information',
          icon: 'x',
        },
        {
          text: 'MITRE-managed limited storage',
          icon: 'x',
        },
      ],
      onPremBullets: [
        'Customize with your own development and features',
        'Can host controlled unclassified information',
        'Complete access-control',
        'Self-managed unlimited storage',
      ],
      featurePic: userPortalImage,
      selectedFeatureCard: 0,
      productionInstances: [
        {
          title: 'MITRE Cyber Academy',
          link: 'https://www.mitrecyberacademy.org/',
        },
        {
          title: 'Web3 Academy',
          link: 'https://xacademy.mitre.org/web3',
        },
        {
          title: 'MITRE GAMEX',
          link: 'https://gamex.mitre.org',
        },
        {
          title: 'Devops Coder Academy',
          link: 'https://www.devopscoders.org',
        },
        {
          title: 'SOCOM Academy',
          link: 'https://xacademy.mitre.org/socom',
        },
        {
          title: '309 SWEG Academy',
          link: 'https://xacademy.mitre.org/swe',
        },
      ],
      featureCards: [
        {
          id: 1,
          pic: userPortalImage,
          title: 'Learn, Practice, and Compete',
          description: 'xAcademy adheres to a "Learn, Practice, Compete" model, guiding learners from novice trainings to advanced competitions.',
        },
        {
          id: 2,
          pic: adminPortalImage,
          title: 'Robust Configuration Options',
          description: 'Admins and content curators can customize the look and feel, populate content, and facilitate competitions from a robust and user-friendly administrative backend.',
        },
        {
          id: 3,
          pic: instancesImage,
          title: 'Knowledge Domain Agnostic',
          description: 'Leveraged for internal training and public STEM outreach in domains ranging from DevOps to Web3, xAcademy can facilitate training for any community of learners.',
        },
      ],
    }
  },
  methods: {
    openContactUsPanel () {
      this.isContactUsFormSubmitted = false
      this.isSuccess = false
      this.isContactUsPanelOpen = true
    },
    openRequestQuotePanel () {
      this.isRequestProposalFormSubmitted = false
      this.isSuccess = false
      this.isRequestQuotePanelOpen = true
      this.scrollToTop()
    },
    scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Optional: Smooth scrolling animation
      })
    },
    toggleBodyScroll (enableScroll) {
      if (enableScroll) {
        document.body.style.overflow = ''
      } else {
        document.body.style.overflow = 'hidden'
      }
    },
    closeAllPanels () {
      this.isContactUsPanelOpen = false
      this.isRequestQuotePanelOpen = false
    },
    handleScroll () {
      this.scrolled = window.scrollY > 0
    },
    async sendRequestProposalEmail () {
      try {
        const response = await Axios.post('/contact-us/proposal', this.intakeFormData)
        const responseData = response.data
        // Check for errors
        if (responseData.errors.length > 0) {
          throw new Error('error sending request')
        }
        return true
      } catch (err) {
        return false
      }
    },
    async sendContactUsEmail () {
      try {
        const response = await Axios.post('/contact-us/request', this.contactUsFormData)
        const responseData = response.data
        // Check for errors
        if (responseData.errors.length > 0) {
          return false
        }
        return true
      } catch (err) {
        return false
      }
    },
    isValidInput () {
      return (
        this.senderName !== '' &&
        this.message !== '' &&
        this.isValidEmail(this.senderEmail)
      )
    },
    // isValidEmail (email) {
    //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    //   return emailRegex.test(email)
    // },
    // clearForm () {
    //   this.message = ''
    //   this.messageSubject = ''
    //   this.senderName = ''
    //   this.senderEmail = ''
    // },
    onSubmitContactUsForm () {
      if (this.isValidInput()) {
        this.sendContactUsEmail()
        this.isContactUsFormSubmitted = true
        setTimeout(() => {
          this.isContactUsPanelOpen = false
          this.clearForm()
          this.isContactUsFormSubmitted = false
        }, 1500)
      } else {
        this.isEmailError = true // as long as one of the three fields' errors is true the message wll show
        setTimeout(() => { this.isEmailError = false }, 3000)
      }

      // this.clearForm()
    },
    isValidEmail (email) {
      return email.split(',').every(e => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailRegex.test(e.trim())
      })
    },
    clearForm () {
      this.message = ''
      this.senderName = ''
      this.senderEmail = ''
      this.isMitreInternal = false
      this.isExistingSponsor = false
      this.isContractorShare = false
      this.isAgileAndDevOps = false
      this.isCyberSecurity = false
      this.isWeb3 = false
      this.isDataManagement = false
      this.isOtherContent = false
      this.otherContentText = ''
      this.selectedHost = ''
      this.isCustomURL = false
      this.isAiAssistant = false
      this.isBadgeEngine = false
      this.isAssessmentEngine = false
      this.isInstallation = false
      this.isTraining = false
      this.isCurriculumDesign = false
      this.isGameDesign = false
      this.isCustomDevelopment = false
      this.isEventFacilitation = false
    },
    onSubmitRequestProposal () {
      this.isNameError = this.senderName.trim() === ''
      this.isEmailError = !this.isValidEmail(this.senderEmail)
      this.isMessageError = this.message.trim() === ''
      this.isHostingError = this.selectedHost === ''
      if (!this.isNameError && !this.isEmailError && !this.isMessageError && !this.isHostingError) {
        this.sendRequestProposalEmail()
        this.isRequestProposalFormSubmitted = true
        setTimeout(() => {
          this.clearForm()
          this.isSuccess = true
        }, 750)
      } else {
        setTimeout(() => {
          this.isNameError = false
          this.isEmailError = false
          this.isMessageError = false
          this.isHostingError = false
        }, 3000)
        this.scrollToTop()
      }
    },
  },
  mounted () {
    tippy('#myButton', {
      content: 'You can populate xAcademy with the content from any of these domains. If you prefer a blank instance, select none.',
    })
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
}
</script>

  <style type='text/css'>
    .fade-to-nothing {
      -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
      mask-image: linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0));
    }
  </style>
