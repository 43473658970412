<template>
  <div class="flex flex-col items-center text-th-text">
    <div :class="{'w-28': logoSize === 'small', 'w-48': logoSize === 'medium', 'max-w-xs': logoSize === 'large', 'max-w-sm': logoSize === 'xl', 'w-24': !logoSize }">
      <img
            v-if="imageData"
            ref="image"
            class="object-contain"
            :src="imageData"
      />
      <DCALogo v-else-if="imageData === null" class="w-full h-full fill-current stroke-current" />
    </div>
    <div class="mt-2 text-3xl font-bold">{{ title }}</div>
  </div>
</template>

<script>
import DCALogo from '@/assets/dca-logo.svg'
import ThemeService from 'metadata-service'

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    logoSize: {
      type: String,
      required: false,
    },
  },
  components: {
    DCALogo,
  },
  async mounted () {
    await this.getLogo()
  },
  data () {
    return {
      imageData: undefined,
    }
  },
  methods: {
    async getLogo () {
      const res = await ThemeService.getLogo()
      this.imageData = res.imageData
    },
  },
}
</script>

<style>
</style>
