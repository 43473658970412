import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Axios from 'axios'
import Toast from './components/utilities/Toast.vue'
import CopyrightFooter from './components/utilities/CopyrightFooter.vue'
import BrandMark from './components/utilities/BrandMark.vue'

import VueCookies from 'vue-cookies'
import Vuelidate from 'vuelidate'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import 'material-icons/iconfont/material-icons.css'
import VueMaterial from 'vue-material'

import { simpleTimestamp } from './utilities/timestampFilters'

import ImageWithAuth from '@/directives/image-with-auth'

import '@/scss/main.scss'

// AXIOS CONFIGURATION

// Get API URL from environment or default for development.
Axios.defaults.baseURL = API_URL || 'http://localhost/'

// Use an interceptor to process authentication.
Axios.interceptors.request.use(config => {
  if (store.state.auth.token) {
    config.headers.common.Authorization = `Bearer ${store.state.auth.token}`
  }

  return config
})

// Response interceptor to handle API errors.
function axiosResponseFailure (error) {
  // If error is 401, log the user out.
  if (error.response && error.response.status === 401) {
    router.push({ name: 'logout' })
  }
  // If other error, maybe we should show a friendly error message.
  // For now just return.
  return Promise.reject(error)
}

// Response interceptor to handle API successes.
function axiosResponseSuccess (response) {
  if (response.data.user) {
    if (response.data.requestPath !== '/logout') {
      store.commit('auth/saveUser', response.data.user)
    }
  }
  return response
}

Axios.interceptors.response.use(axiosResponseSuccess, axiosResponseFailure)

Vue.config.productionTip = false
Vue.use(VueCookies)
Vue.use(Vuelidate)
Vue.use(VueMaterial)

Vue.filter('simpleDate', simpleTimestamp)

Vue.directive('image-with-auth', ImageWithAuth)

Vue.component('Toast', Toast)
Vue.component('CopyrightFooter', CopyrightFooter)
Vue.component('BrandMark', BrandMark)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
