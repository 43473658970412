<template>
  <div class="max-w-full flex flex-row relative items-center">
    <div class="w-full">
      <carousel
        ref="carousel"
        :perPage="1"
        :scrollPerPage="true"
        :paginationEnabled="false"
        :mouseDrag="false"
        @page-change="pageChanged"
      >
        <slot></slot>
      </carousel>
    </div>
  </div>
</template>

<script>
import { Carousel } from 'vue-carousel'

export default {
  components: {
    Carousel,
  },
  props: {
    currentPage: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    currentPage (newVal, oldVal) {
      this.$refs.carousel.goToPage(newVal)
    },
  },
  mounted () {
    // console.log(this.$refs.carousel)
    this.numPages = this.$refs.carousel.pageCount
    if (this.numPages <= 1) {
      this.hideRight = true
    }
  },
  data () {
    return {
      numPages: 0,
      hideLeft: true,
      hideRight: false,
      siteMetadata: undefined,
    }
  },
  methods: {
    pageChanged (evt) {
      if (evt === 0) {
        this.hideLeft = true
      } else {
        this.hideLeft = false
      }

      if (evt === this.numPages - 1) {
        this.hideRight = true
      } else {
        this.hideRight = false
      }
    },
  },
}
</script>

<style lang="postcss">
.VueCarousel-slide {
  display: flex;
  justify-content: center;
}
button.VueCarousel-dot.VueCarousel-dot--active:focus {
  outline: none !important;
}
button.VueCarousel-dot:focus {
  outline: none !important;
}
</style>
