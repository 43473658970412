import { fetchTeams, addTeam, getCollectionById, getTeamById, deleteTeam, createCollection, deleteCollection, inviteUser, acceptInvite, declineInvite, deleteInvite, deleteUser, updateTeam, addItemToCollection, removeItemFromCollection } from 'team-service'

export default {
  namespaced: true,
  state: {
    teams: [],
    collection: [],
    // to explain why teams and allTeams: all references to allTeams were added long after teams (which is really user teams)
    allTeams: [],
  },
  getters: {
    teams: (state) => {
      return state.teams
    },
    allTeams: (state) => {
      return state.allTeams
    },
    collection: (state) => {
      return state.colection
    },
    //   lessons: (state) => {
    //     return state.lessons
    //   }
  },
  mutations: {
    updateTeams (state, teams) {
      state.teams = teams
    },
    updateAllTeams (state, teams) {
      state.allTeams = teams
    },
    updateCollection (state, collection) {
      state.collection = collection
    },
    //   updateLessons (state, lessons) {
    //     state.lessons = lessons
    //   }
  },
  actions: {
    async fetchAllTeams ({ commit }) {
      const teams = await fetchTeams()
      commit('updateAllTeams', teams)
    },
    async fetchTeams ({ commit }, userTeams) {
      const teams = []
      for (const userTeam of userTeams) {
        const team = await getTeamById(userTeam._id)

        if (team) {
          teams.push(team)
        }
      }
      commit('updateTeams', teams)
    },
    async addTeam (context, team) {
      return await addTeam(team)
    },
    // async updateTrack (context, track) {
    //   return await updateTrack(track)
    // },
    async deleteTeam (context, teamId) {
      return await deleteTeam(teamId)
    },
    async updateTeam (context, team) {
      return await updateTeam(team)
    },
    // /* COURSES */
    // async fetchCourses ({ commit }) {
    //   const courses = await fetchCourses()
    //   commit('updateCourses', courses)
    // },
    async createCollection (context, { collection, id }) {
      return await createCollection(collection, id)
    },
    async deleteCollection (context, { teamID, collectionID }) {
      return await deleteCollection(teamID, collectionID)
    },
    async inviteUser (context, { userEmail, teamID }) {
      return await inviteUser(userEmail, teamID)
    },
    async acceptInvite (context, { userID, teamID }) {
      return await acceptInvite(userID, teamID)
    },
    async declineInvite (context, { userID, teamID }) {
      return await declineInvite(userID, teamID)
    },
    async deleteInvite (context, { userID, teamID }) {
      return await deleteInvite(userID, teamID)
    },
    async deleteUser (context, { teamID, userID }) {
      return await deleteUser(teamID, userID)
    },
    async removeItemFromCollection (context, { teamId, collectionId, itemId }) {
      return await removeItemFromCollection(teamId, collectionId, itemId)
    },
    async getCollectionById ({ commit }, { teamId, collectionId }) {
      const collection = await getCollectionById(teamId, collectionId)
      commit('updateCollection', collection)
    },
    async addItemToCollection (context, { teamId, collectionId, itemToAdd }) {
      return await addItemToCollection(teamId, collectionId, itemToAdd)
    },
    // async linkTracks ({ commit }, { id, tracks }) {
    //   tracks.forEach((track) => {
    //     linkTrack(id, track.key)
    //   })
    // },
    // async unlinkTracks ({ commit }, { id, tracks }) {
    //   tracks.forEach((track) => {
    //     unlinkTrack(id, track.key)
    //   })
    // },
    // async deleteCourse (context, courseId) {
    //   return await deleteCourse(courseId)
    // },
    // /* Lessons */
    // async fetchLessons ({ commit }) {
    //   const lessons = await fetchLessons()
    //   commit('updateLessons', lessons)
    // },
    // async addLesson (context, lesson) {
    //   return await addLesson(lesson)
    // },
    // async updateLesson (context, lesson) {
    //   return await updateLesson(lesson)
    // },
    // async linkCourses ({ commit }, { id, courses }) {
    //   courses.forEach((course) => {
    //     linkCourse(id, course.key)
    //   })
    // },
    // async unlinkCourses ({ commit }, { id, courses }) {
    //   courses.forEach((course) => {
    //     unlinkCourse(id, course.key)
    //   })
    // },
    // async deleteLesson (context, lessonId) {
    //   return await deleteLesson(lessonId)
    // }
  },
}
