<template>
  <div v-if="visible" class="w-full flex justify-center absolute top-0 left-0 right-0 pt-6 px-6">
    <div class="text-white px-6 py-2 rounded-lg text-center" :class="classes">
      <div class="font-bold text-lg uppercase">
        <div v-if="$slots.title">
          <slot name="title"></slot>
        </div>
        <div v-else>{{ title }}</div>
      </div>
      <div class="text-sm uppercase font-light">
        <div v-if="$slots.msg">
          <slot name="msg"></slot>
        </div>
        <div v-else>{{ msg }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
    },
    msg: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: 'default',
    },
    value: {
      type: Boolean,
      default: false,
    },
    autoClose: {
      type: Number,
    },
    width: {
      type: String,
      required: false,
      default: 'w-1/3',
    },
  },
  computed: {
    classes () {
      return {
        [`w-full lg:${this.width}`]: true,
        'bg-gray-500': this.type === 'default',
        'bg-red-800': this.type === 'danger',
        'bg-green-700': this.type === 'success',
        'bg-mitre-blue': this.type === 'info',
        'bg-yellow-500': this.type === 'warning',
      }
    },
  },
  data () {
    return {
      visible: this.value,
    }
  },
  watch: {
    value () {
      if (this.value) {
        this.visible = true
        if (this.autoClose) {
          setTimeout(() => {
            this.visible = false
            this.$emit('input', false)
          }, this.autoClose * 1000)
        }
      }
    },
  },
}
</script>

<style>
</style>
